import Calculator from "./components/Calculator";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#3d4f6f",
        contrastText: "#fff",
      },
      secondary: {
        main: "#FFBC8B",
        contrastText: "#fff",
      },
    },
    typography: {
      allVariants: {
        color: "#3D4F6F",
        fontFamily: ["Nunito Sans", "sans-serif"].join(","),
      },
      h2: {
        fontFamily: ["Old Standard TT", "serif"].join(","),
      },
      h3: {
        fontFamily: ["Old Standard TT", "serif"].join(","),
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className={"container"}>
        <Header />
        <Calculator/>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
