import React from "react";
import { Grid, Typography, Divider} from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const Footer = () => {
  return (
    <div>
      <Grid container style={{ marginTop: "40px", right:"0", bottom:"0"}}>
        <Grid item md={12} sm={12} align="center">
          <Divider>
            <Typography variant="h2" gutterBottom>
              Kapcsolat
            </Typography>
          </Divider>
          <Typography gutterBottom>
            Kérdéssel kapcsolatban, kérjük vegyék fel velünk a kapcsolatot az
            alábbi elérhetőségek egyikén.
          </Typography>
          <LocalPhoneOutlinedIcon color="primary" />
          <Typography gutterBottom>+36 70 946 0470</Typography>
          <LocationOnOutlinedIcon />
          <Typography gutterBottom color="primary">
            4031 Debrecen, Kerekes Ferenc utca 2
          </Typography>
          <EmailOutlinedIcon color="primary" />
          <Typography gutterBottom>info@sincro.hu</Typography>
        </Grid>
      </Grid>
      
    </div>
  );
};

export default Footer;
