import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Stack,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Grid,
  Box,
} from "@mui/material";
import { useState } from "react";

const Calculator = () => {
  const [konyvezetesiForma, setKonyvezetesiForma] = useState(2);
  const [afaBevGyakorisag, setAfaBevGyakorisag] = useState(0);
  const [osszSzamla, setOsszSzamla] = useState("");
  const [importSzamla, setImportSzamla] = useState("");
  const [tanacsadas, setTanacsadas] = useState("");
  const [bizonylatNyomtatas, setBizonylatNyomtatas] = useState(""); //db
  const [impExpUgyletek, setimpExpUgyletek] = useState(""); //db
  const [szamfejtettSzemelyek, setSzamfejtettSzemelyek] = useState("");
  const [utazasKm, setUtazasKm] = useState("");
  const [kshJelentes, setKshJelentes] = useState("");
  const [ktdBevallas, setKtdBevallas] = useState("");

  const [rendezettIrattartas, setRendezettIrattartas] = useState(false);
  const [egyediNyilvantartas, setEgyediNyilvantartas] = useState(false);
  const [konyvvizsgKotelezettseg, setKonyvvizsgKotelezettseg] = useState(false);
  const [evesBeszamolo, setevesBeszamolo] = useState(false);

  const [kulonlegesKontrolling, setKulonlegesKontrolling] = useState(false);
  const [dyntellERPhasznalat, setDyntellERPhasznalat] = useState(false);

  const [kalkulalt, setKalkulalt] = useState(false);
  const [kalkulaltHaviDij, setkalkulaltHaviDij] = useState("");
  const [szemelyekkelKapcsDij, setszemelyekkelKapcsDij] = useState("");
  const [sum, setSum] = useState("");

  const [emailLink, setEmailLink] = useState(
    "mailto:istvan.rozsa@sincro.hu?subject=Könyvelési díj ajánlat érdeklődés&body=Az email szövege"
  );

  const createEmailBody = () => {
    let forma = "";
    if (konyvezetesiForma === 0) {
      forma = "Bevétel nyilvántartás";
    } else if (konyvezetesiForma === 1) {
      forma = "Egyszeres könyvelés";
    } else if (konyvezetesiForma === 2) {
      forma = "Kettős könyvelés";
    }

    let gyak = "";
    if (afaBevGyakorisag === 0) {
      gyak = "Alanyi mentes";
    } else if (afaBevGyakorisag === 20) {
      forma = "Éves";
    } else if (afaBevGyakorisag === 30) {
      forma = "Negyedéves";
    } else if (afaBevGyakorisag === 40) {
      forma = "Havi";
    }

    const rendiratadadas = rendezettIrattartas? "igen":"nem";
    const egyedinyilv = egyediNyilvantartas? "igen":"nem";
    const konyvkot = konyvvizsgKotelezettseg? "igen":"nem";
    const evesbesz = evesBeszamolo? "igen":"nem";
    const kulkontr = kulonlegesKontrolling? "igen":"nem";
    const dyntellerp = dyntellERPhasznalat? "igen":"nem";


    var formattedBody =
      "Az általam kalkuláltak: "+ "\n" +
      "Könyvvezetési forma: " + forma + "\n" +
      "Áfa bevallás gyakorisága: " + gyak + "\n" +
      "Éves számla mennyiség: " + osszSzamla + "\n" +
      "Importálható számlák száma: " + importSzamla + "\n" +
      "Havi tanácsadási órák száma: " + tanacsadas + "\n" +
      "Bizonylat nyomtatás (db): " + bizonylatNyomtatas + "\n" +
      "Import - Export ügyletek száma: " + impExpUgyletek + "\n" +
      "Számfejtett személyek száma: " + szamfejtettSzemelyek + "\n" +
      "Utazási költség (km): " + utazasKm + "\n" +
      "KSH jelentések száma: " + kshJelentes + "\n" +
      "KTD jelentések száma: " + ktdBevallas + "\n" +
      "Rendezett iratátadás: " + rendiratadadas + "\n" +
      "Egyedi nyilvántartás: " + egyedinyilv + "\n" +
      "Könyvvizsgálati kötelezettség: " + konyvkot + "\n" +
      "Éves beszámoló: " + evesbesz + "\n" +
      "Különleges Kontrolling: " + kulkontr + "\n" +
      "Dyntell ERP használat: " + dyntellerp + "\n" +
      
      "----" + "\n" +
      "Kalkulált havidíj: " + kalkulaltHaviDij + " Ft"+"\n" +
      "Személyekkel kapcsolatos havidíj: " + szemelyekkelKapcsDij + " Ft"+"\n" +
      "Összesen: " + sum + " Ft/hó"
      ;
    let baseLink =
      "mailto:istvan.rozsa@sincro.hu?subject=Könyvelési díj ajánlat érdeklődés&body="
      + encodeURIComponent(formattedBody);
      console.log(baseLink);
      setEmailLink(baseLink);
  };

  const arSzamitas = () => {
    let alapDij = 0;
    if (konyvezetesiForma === 0) {
      alapDij = 6300;
    } else if (konyvezetesiForma === 1) {
      alapDij = 12000;
    } else if (konyvezetesiForma === 2) {
      alapDij = 12000;
    }
    console.log("Alapdíj Könyvvezetési Forma szerint: ", alapDij);
    const bizDij = 2000; // 240 bizonylat felett 100db-ként 2000 HUF
    const tobbletBizonylat =
      parseInt(osszSzamla) > 240 ? parseInt(osszSzamla) - 240 * 1.1 : 0;

    console.log("Többlet Bizonylat: ", tobbletBizonylat);
    const tanacsadasiAr = tanacsadas > 1 ? (tanacsadas -1) * 11000 : 0;
    const alap =
      parseInt(osszSzamla) > 240
        ? alapDij + (bizDij * tobbletBizonylat) / 100 + tanacsadasiAr
        : alapDij + tanacsadasiAr;

    console.log("Alapdíj többlet bizonylattal + tanácsadással: ", alap);

    const gyakorisagiAr = alap * (afaBevGyakorisag / 100);
    console.log("Gyakorisági ár: ", gyakorisagiAr);

    const bizNyomtatasAr = bizonylatNyomtatas * 15;
    console.log("Bizonylat Nyomtatási Ár: ", bizNyomtatasAr);

    const rendezettlenIrattartasAr = rendezettIrattartas ? 0 : alap * 0.2;
    console.log("Rendezett irattartás: ", rendezettlenIrattartasAr);

    const impExportAr = impExpUgyletek > 10 ? alap * 0.1 : 0;
    console.log("Import export Ügyletek ár: ", impExportAr);

    const egyediNyilvVezAr = egyediNyilvantartas ? alap * 0.2 : 0;
    console.log("Egyedi Nyilvántartás ár: ", egyediNyilvVezAr);

    const evesBeszamoloAr = evesBeszamolo ? alap * 0.2 : 0;
    console.log("Éves Beszámoló Ár: ", evesBeszamoloAr);

    const konyvKotAr = konyvvizsgKotelezettseg ? alap * 0.1 : 0;
    console.log("Könyvvizsgálati Kötelezettség Ár: ", konyvKotAr);

    const utazasiAr = utazasKm * 150;
    console.log("Utazási ár: ", utazasiAr);

    const kulonlegesKontrollingAr = kulonlegesKontrolling ? alap * 0.1 : 0;
    console.log("Különleges Kontrolling Ár: ", kulonlegesKontrollingAr);

    const kshJelentesAr = kshJelentes * 2700;
    console.log("KSH Jelentés ár:", kshJelentesAr);

    const ktdBevallasAr = ktdBevallas * 4000;
    console.log("KTD Bevallás ára: ", ktdBevallasAr);

    const dyntellERPhasznalatArKedvezmeny = dyntellERPhasznalat
      ? alap * 0.3
      : 0;
    console.log("Dyntell kedvezmény: ", dyntellERPhasznalatArKedvezmeny);

    const kedvezmenySzazalek = (importSzamla * 0.4) / osszSzamla;
    console.log("Kedvezmenyszazalek: ", kedvezmenySzazalek, "AlapDíj: ", alap);
    const importKedvezmeny = Math.round(kedvezmenySzazalek * alap);
    console.log("Import Kedvezmeny Ar: ", importKedvezmeny);

    const calculation =
      alap +
      gyakorisagiAr +
      bizNyomtatasAr +
      rendezettlenIrattartasAr +
      impExportAr +
      egyediNyilvVezAr +
      evesBeszamoloAr +
      utazasiAr +
      konyvKotAr +
      kshJelentesAr +
      ktdBevallasAr +
      kulonlegesKontrollingAr -
      dyntellERPhasznalatArKedvezmeny -
      importKedvezmeny;

    const szemelydij = parseInt(szamfejtettSzemelyek) * 2700;
    setszemelyekkelKapcsDij(szemelydij);
    setkalkulaltHaviDij(calculation);
    setSum(szemelydij + calculation);
    setKalkulalt(true);
  };

  const handleSubmit = (e) => {
    arSzamitas();
    e.preventDefault();
  };

  const handlerendezettIrattartas = (event) => {
    setRendezettIrattartas(event.target.checked);
  };

  const handleegyediNyilvantartas = (event) => {
    setEgyediNyilvantartas(event.target.checked);
  };

  const handlekonyvvizsgKotelezettseg = (event) => {
    setKonyvvizsgKotelezettseg(event.target.checked);
  };

  const handleevesBeszamolo = (event) => {
    setevesBeszamolo(event.target.checked);
  };

  const handlekulonlegesKontrolling = (event) => {
    setKulonlegesKontrolling(event.target.checked);
  };

  const handledyntellERPhasznalat = (event) => {
    setDyntellERPhasznalat(event.target.checked);
    setImportSzamla(0);
  };

  return (
    <Container style={{ paddingTop: "20px" }} maxWidth="lg">
      <Typography variant="h2" gutterBottom align="center">
        Árkalkulátor
      </Typography>
      <form onSubmit={handleSubmit} style={{ paddingBottom: "20px" }}>
        <Grid container>
          <Grid item md={4} xs={12} style={{ padding: "10px" }}>
            <Stack spacing={2}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      checked={rendezettIrattartas}
                      onChange={handlerendezettIrattartas}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Rendezett irat átadás"
                />
              </FormControl>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      checked={dyntellERPhasznalat}
                      onChange={handledyntellERPhasznalat}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Dyntell ERP használat"
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Könyvezetési forma
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={konyvezetesiForma}
                  label="Könyvvezetési forma"
                  onChange={(e) => setKonyvezetesiForma(e.target.value)}
                >
                  <MenuItem value={0}>Bevétel nyilvántartás</MenuItem>
                  <MenuItem value={1}>Egyszeres könyvelés</MenuItem>
                  <MenuItem value={2}>Kettős könyvelés</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Áfa bevallás gyakorisága
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={afaBevGyakorisag}
                  label="Könyvvezetési forma"
                  onChange={(e) => setAfaBevGyakorisag(e.target.value)}
                >
                  <MenuItem value={0}>Alanyi mentes</MenuItem>
                  <MenuItem value={20}>Éves</MenuItem>
                  <MenuItem value={30}>Negyedéves</MenuItem>
                  <MenuItem value={40}>Havi</MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                id="outlined-number"
                label="Éves számla mennyiség"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                }} // eslint-disable-line
                value={osszSzamla}
                onChange={(e) => setOsszSzamla(e.target.value)}
              />
              {dyntellERPhasznalat ? (
                <></>
              ) : (
                <TextField
                  required
                  id="outlined-number"
                  label="Importálható számlák száma"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                  }} // eslint-disable-line
                  value={importSzamla}
                  onChange={(e) => setImportSzamla(e.target.value)}
                />
              )}
            </Stack>
          </Grid>

          <Grid item md={4} xs={12} style={{ padding: "10px" }}>
            <Stack spacing={2}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      checked={egyediNyilvantartas}
                      onChange={handleegyediNyilvantartas}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Egyedi nyilvántartás"
                />
              </FormControl>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      checked={konyvvizsgKotelezettseg}
                      onChange={handlekonyvvizsgKotelezettseg}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Könyvvizsgálati kötelezettség"
                />
              </FormControl>
              <TextField
                required
                id="outlined-number"
                label="Havi tanácsadási órák száma (óra)"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                }} // eslint-disable-line
                value={tanacsadas}
                onChange={(e) => setTanacsadas(e.target.value)}
              />
              <TextField
                required
                id="outlined-number"
                label="Bizonylat nyomtatás (db)"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                }} // eslint-disable-line
                value={bizonylatNyomtatas}
                onChange={(e) => setBizonylatNyomtatas(e.target.value)}
              />

              <TextField
                required
                id="outlined-number"
                label="Import - Export ügyletek száma"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                }} // eslint-disable-line
                value={impExpUgyletek}
                onChange={(e) => setimpExpUgyletek(e.target.value)}
              />
              <TextField
                required
                id="outlined-number"
                label="Számfejtett személyek száma"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                }} // eslint-disable-line
                value={szamfejtettSzemelyek}
                onChange={(e) => setSzamfejtettSzemelyek(e.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item md={4} xs={12} style={{ padding: "10px" }}>
            <Stack spacing={2}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      checked={evesBeszamolo}
                      onChange={handleevesBeszamolo}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Éves beszámoló"
                />
              </FormControl>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      checked={kulonlegesKontrolling}
                      onChange={handlekulonlegesKontrolling}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Különleges kontrolling szolgáltatás"
                />
              </FormControl>
              <TextField
                required
                id="outlined-number"
                label="Utazási költség (km)"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                }} // eslint-disable-line
                value={utazasKm}
                onChange={(e) => setUtazasKm(e.target.value)}
              />

              <TextField
                required
                id="outlined-number"
                label="KSH jelentések száma"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                }} // eslint-disable-line
                value={kshJelentes}
                onChange={(e) => setKshJelentes(e.target.value)}
              />
              <TextField
                required
                id="outlined-number"
                label="KTD bevallások száma"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "^[+-]?([0-9]+.?[0-9]*|.[0-9]+)",
                }} // eslint-disable-line
                value={ktdBevallas}
                onChange={(e) => setKtdBevallas(e.target.value)}
              />
            </Stack>
          </Grid>
        </Grid>
        <Box textAlign="center">
          <Button
            variant="contained"
            type="submit"
            style={{ marginBottom: "30px", boxShadow: "none" }}
          >
            Kalkulálok!
          </Button>
        </Box>

        <Box textAlign="center">
          {kalkulalt ? (
            <>
              <Typography variant="h6">
                Kalkulált könyvelési havidíj:
              </Typography>
              <Typography variant="h5">
                {kalkulaltHaviDij
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                Ft
              </Typography>
              <Typography variant="h6">
                Személyekkel kapcsolatos havidíj:
              </Typography>
              <Typography variant="h5" gutterBottom>
                {szemelyekkelKapcsDij
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                Ft
              </Typography>
              <Typography variant="h6">Összesen(nettó):</Typography>
              <Typography variant="h4" gutterBottom>
                {sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó
              </Typography>
              <Typography>
                A kalkulált árak tájékoztató jellegűek, a pontos ajánlatért és
                részletekért kérem keressen minket a megadott elérhetőségek
                egyikén.
              </Typography>

              <Button
                variant="contained"
                color="secondary"
                href={emailLink}
                type="submit"
                style={{ marginTop: "30px", boxShadow: "none" }}
                onClick={createEmailBody}
              >
                További részleteket kérek emailben
              </Button>
            </>
          ) : (
            <></>
          )}
        </Box>
      </form>
    </Container>
  );
};

export default Calculator;
