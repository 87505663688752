import React from "react";
import { AppBar, Toolbar, Typography, Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Header = () => {
  return (
    <div>
      <AppBar
        position="sticky"
        style={{ background: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <Button startIcon={<ArrowBackIcon />} href="https://sincro.hu/">
            Vissza a fő oldalra
          </Button>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Avatar 
          variant="square"
          src="https://sincro.hu/wp-content/uploads/2020/09/cropped-Sincro-color.png"
          >
            N
          </Avatar>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
